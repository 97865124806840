var f = Object.defineProperty;
var y = (l, n, t) => n in l ? f(l, n, { enumerable: !0, configurable: !0, writable: !0, value: t }) : l[n] = t;
var c = (l, n, t) => y(l, typeof n != "symbol" ? n + "" : n, t);
import { l as u } from "./chunks/index.js";
import { setLoginFlowState as T, getUTMParams as S, fetcher as v, whenPrerenderingActivated as L, toSlug as h } from "@vgno/utils";
const w = {
  dinepenger: "dp",
  vg: "vg"
};
class N extends HTMLElement {
  constructor() {
    super();
    c(this, "contentId");
    c(this, "contentType");
    c(this, "deviceType");
    c(this, "isApp");
    c(this, "newsroom");
    c(this, "state");
    this.contentId = this.dataset.contentId || "unknown", this.contentType = this.dataset.contentType, this.deviceType = this.dataset.deviceType, this.isApp = this.dataset.isApp === "true", this.newsroom = this.dataset.newsroom || "vg", this.state = {
      isLoading: !0,
      stripe: null
    }, this.onLoad = this.onLoad.bind(this);
  }
  constructUrl() {
    return new URL(
      `https://cm.vg.no/v2/${w[this.newsroom] || "vg"}/stripe`
    );
  }
  createLoginUrl(t) {
    var a;
    const s = new URL(window.location.href);
    s.searchParams.set("utm_source", "stripe"), t != null && t.contentName && s.searchParams.set(
      "utm_medium",
      t == null ? void 0 : t.contentName
    ), t != null && t.campaign && s.searchParams.set(
      "utm_campaign",
      t.campaign
    ), t != null && t.segmentName && s.searchParams.set(
      "utm_term",
      t.segmentName
    );
    const i = T(s.toString());
    return (a = window.Identity) == null ? void 0 : a.loginUrl({
      state: i
    });
  }
  async fetchStripeData() {
    const s = new AbortController().signal;
    if (this.isApp) {
      this.updateLoadingState(!1);
      return;
    }
    const i = S(window.location.toString(), !0), a = this.constructUrl();
    try {
      const o = await v(a.toString(), {
        credentials: "include",
        signal: s
      });
      if (o.status === 204) {
        this.updateLoadingState(!1);
        return;
      }
      if (o.status !== 200)
        throw new Error(`Failed to fetch stripe data: ${o.status}`);
      const p = u.createSalesposter(o, {
        contentProvider: w[this.newsroom] || "vg",
        contentType: this.contentType,
        deviceType: this.deviceType,
        paywallType: "stripe",
        contentId: this.contentId,
        document,
        window,
        utmTagOverrides: i ? {
          utm_campaign: i.campaign,
          utm_content: i.content,
          utm_medium: i.medium,
          utm_source: i.source,
          utm_term: i.terms,
          utm_url: i.url
        } : void 0,
        generateLoginUrl: (e) => this.createLoginUrl(e)
      });
      this.state.stripe = p;
    } catch (o) {
      console.error("Failed to generate stripe", o);
    } finally {
      this.updateLoadingState(!1);
    }
  }
  onLoad(t) {
    try {
      const s = t.salesposter.html.replace(
        /{{article_id}}/g,
        this.contentId
      );
      this.shadowRoot.innerHTML = "";
      const a = new DOMParser().parseFromString(s, "text/html");
      a.querySelectorAll("style").forEach((e) => {
        this.shadowRoot.appendChild(e.cloneNode(!0));
      }), Array.from(a.body.childNodes).forEach((e) => {
        this.shadowRoot.appendChild(e.cloneNode(!0));
      }), this.shadowRoot.querySelectorAll("script").forEach((e) => {
        const r = document.createElement("script");
        e.src ? r.src = e.src : r.textContent = e.textContent, e.parentNode.replaceChild(r, e);
      }), this.trackView(), sessionStorage.removeItem("hasSession-cache");
    } catch (s) {
      console.error("Failed to load stripe", s);
    }
  }
  async trackView() {
    var a, o, p, e, r, m, d, g;
    await L();
    const t = window.nmTrackerSync;
    if (!t) {
      console.error(
        "News Media Pulse not initialized. Make sure to load the NM tracker (v7+) before the salesposter."
      );
      return;
    }
    const s = document.documentElement.dataset.pageType, i = [
      "salesposter",
      h(((a = this.state.stripe) == null ? void 0 : a.salesposter.posterType) || "stripe"),
      h(((o = this.state.stripe) == null ? void 0 : o.salesposter.contentName) || "unknown"),
      (p = this.state.stripe) != null && p.salesposter.campaign ? h(this.state.stripe.salesposter.campaign) : void 0,
      (e = this.state.stripe) != null && e.salesposter.segmentName ? h(this.state.stripe.salesposter.segmentName) : void 0,
      (r = this.state.stripe) != null && r.salesposter.abTestGroup ? h(this.state.stripe.salesposter.abTestGroup) : void 0
    ].filter(Boolean);
    t.trackViewUIElement({
      origin: (m = this.state.stripe) == null ? void 0 : m.pulseOrigin,
      object: {
        name: ((d = this.state.stripe) == null ? void 0 : d.salesposter.contentName) || "Salesposter Stripe",
        elementType: ((g = this.state.stripe) == null ? void 0 : g.salesposter.posterType) || "Salesposter - Stripe",
        id: i.join(":"),
        page: {
          id: this.contentId,
          url: window.location.href,
          type: s || "Page"
        },
        placement: {
          primaryPosition: 1
        }
      }
    });
  }
  updateLoadingState(t) {
    this.state.isLoading = t, this.render();
  }
  connectedCallback() {
    this.shadowRoot || this.attachShadow({ mode: "open" }), this.fetchStripeData();
  }
  render() {
    !this.state.stripe || this.state.isLoading || this.isApp || this.onLoad(this.state.stripe);
  }
}
const _ = () => {
  customElements.get("vg-stripe") || customElements.define("vg-stripe", N);
};
export {
  _ as default
};
