// ../svp-api-client/dist/index.js
var GODARE_CATEGORY_ID = 1208;
var Vendor = {
  Aftonbladet: "ab",
  Godare: "godare"
};
var CHILD_GODARE_CATEGORY_IDS = [
  1062,
  1221,
  1268,
  1306,
  1307,
  1308,
  1309,
  1325,
  1327,
  1328,
  1329,
  1330,
  1342,
  1343,
  1372,
  1384,
  1425,
  1426,
  1427,
  1428,
  1430,
  1437,
  1439,
  1440,
  1441,
  1447,
  1449,
  1450,
  1451,
  1452,
  1453,
  1462,
  1463,
  1464,
  1473,
  1474,
  1476,
  1477,
  1492,
  1493,
  1504,
  1513,
  1515,
  1520,
  1522
];
var resolveVendor = (vendor) => {
  if (vendor === Vendor.Godare) {
    return Vendor.Aftonbladet;
  }
  return vendor;
};
var resolveFilters = (vendor, filter) => {
  if (vendor === Vendor.Godare) {
    const filters = (filter != null ? filter : "").split("|");
    filters.push(`category.id::${GODARE_CATEGORY_ID}`);
    return filters.join("|");
  }
  if (vendor === Vendor.Aftonbladet) {
    const filters = (filter != null ? filter : "").split("|");
    filters.push(`category.id<>${[...CHILD_GODARE_CATEGORY_IDS, GODARE_CATEGORY_ID].join(",")}`);
    return filters.join("|");
  }
  return filter;
};
var SVP_ASSET_ADDITIONAL = [
  "settings",
  "chapters",
  "cuePoints",
  "externalId",
  "externalCategoryId",
  "nextAsset",
  "sourceFiles",
  "subtitles",
  "metadata",
  "access",
  "tags",
  "stories"
].join("|");

// src/index.ts
var getRelatedAssets = (client) => async ({
  assetId,
  limit,
  vendor,
  filters = []
}, options) => {
  const searchParams = new URLSearchParams();
  if (limit) {
    searchParams.set("limit", String(limit));
  }
  const filter = resolveFilters(vendor, ["category.showCategory::true", ...filters].join("|"));
  if (filter) {
    searchParams.set("filter", filter);
  }
  searchParams.set("additional", SVP_ASSET_ADDITIONAL);
  const response = await client(`/${resolveVendor(vendor)}/${assetId}`, {
    ...options,
    searchParams
  });
  return response.json();
};
var createRelatedApi = (client) => {
  return {
    getRelatedAssets: getRelatedAssets(client)
  };
};
export {
  createRelatedApi
};
